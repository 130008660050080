/* eslint-disable no-shadow */
import { IconifyAPIPrepareQuery } from '@iconify/react-with-api';
import {
  ICampaignFormat,
  ICampaignPublishPph,
  IListLocation,
  ILocation,
  ILocationContentType,
  ILocationFrequency,
  ILocationFull,
  ILocationSchedule,
} from 'interface/ILocation';
import { IScheduler } from 'interface/IScheduler';
import { v4 as uuidv4 } from 'uuid';
import { DateTime } from 'luxon';
import { IMediaUpload } from 'interface/IMedia';
import { IRulesMediaCard } from 'interface';
import { StringDecoder } from 'string_decoder';
import { gtmAddToCart, gtmRemoveFromCart } from 'api/gtm/gtm';
import { calculateSlots } from '@utils/calculateSlots';
import { JWT } from '@api/users';
export enum IPublishType {
  setBasic = 'set-basic',
  setMapLocation = 'set-basic',
  addLocation = 'add-location',
  addLocations = 'add-locations',
  removeLocation = 'remove-location',
  addToGlobal = 'add-to-global',
  linkAllToGlobal = 'link-all-to-global',
  addAll = 'add-all',
  setPph = 'set-pph',
  createPph = 'set-custom-pph',
  updatePph = 'update-pph',
  resetLocations = 'reset-locations',
  // createSchedule = 'create-new-schedule',
  setScheduleSlots = 'set-schedule-slots',
  addMediaToFormat = 'add-media-to-format',
  adMultipledMediaToFormat = 'add-multiple-media-to-format',
  removeMediaToFormat = 'remove-media-to-format',
  addRulesToMedia = 'add-rules-to-media',
  setAgregateData = 'set-agregate-values',
  setCredit = 'set-credit',
  setMedia = 'set-media',
  addFormats = 'add-formaaddFormatsts',
  setPercentageVoucher = 'set-percentage-voucher',
  removePercentageVoucher = 'remove-percentage-voucher',
  restartState = 'restart-state',
  setMapZoom = 'set-map-zoom',
  setMapCenter = 'set-map-center',
  rehydrate = 'rehydrate',
  setMediaDuration = 'set-media-duration',
  updateVideoCardDuration = 'update-video-card-duration',
}
export enum SourceType {
  places = 'places',
  mobile = 'places-mobile',
  spectacular = 'places',
  indoor = 'places-indoor',
}

export type ICampaignPublish = {
  step: number;
  toggleMedia: number;
  toggleSlots: number;
  toggleListOnlyBook: number;
  name: string;
  category: string;
  industry: string;
  removedLocation: string;
  type: string;
  parentId: string;
  readOnly?: boolean;
  adomain: string;
  recalculateReach: any;
  slots?: number[];
  startDate: number;
  removedItems: string[];
  mapLocations?: ILocation[];
  locations?: IListLocation[];
  pph?: ICampaignPublishPph[];
  schedule?: IScheduler[];
  formats: ICampaignFormat[];
  price: string;
  reach: number;
  uuid: string;
  credit: number;
  usedCredits: number;
  vatCredits: number;
  coupon?: string;
  center: [number, number];
  zoom: [number];
};
type IPublishAction = {
  payload: any;
  type: IPublishType;
};
let now = DateTime.now().startOf('day');

export const initialState: ICampaignPublish = {
  name: '',
  category: '',
  step: 1,
  removedLocation: '',
  removedItems: [],
  parentId: '',
  adomain: '',
  startDate: now.plus({ days: 2 }).toMillis(),
  industry: '',
  toggleMedia: 0,
  toggleSlots: 0,
  toggleListOnlyBook: 0,
  type: '',
  formats: [],
  price: '0',
  credit: 0,
  recalculateReach: 0,
  usedCredits: 0,
  vatCredits: 0,
  schedule: [
    {
      startDate: now.plus({ days: 2 }).toMillis(),
      readOnly: false,
      title: '',
      type: ILocationSchedule.Hourly,
      isGlobal: true,
      uuid: 'global',
      isDefault: false,
      frequencies: [],
      pph: 0,
      locations: [
        {
          reachCircuit: 0,
          uuid: '1',
          duration: 1,
          acceptedDurations: [1],
          name: 'Global Schedule',
          restriction: [],
          restrictionsFormat: [],
          rt: 1,
          pC: false,
          frequencies: [
            {
              seconds: 1,
              label: '1',
              uuid: '1',
            },
          ],
          circuit: 1,
          pph: 1,
          minBookingHours: -1,
        },
      ],
      slots: [],
    },
  ],
  reach: 0,
  uuid: uuidv4(),
  center: [1, 1],
  zoom: [2],
};

const setBasic = (state: ICampaignPublish, payload: any) => {
  const schedule = state.schedule?.map(el => {
    const slots = el.slots.filter(slot => {
      return slot > now.plus({ days: payload.date }).toMillis() / 1000;
    });

    return {
      ...el,
      slots: slots,
      startDate: now.plus({ days: payload.date }).toMillis(),
    };
  });

  return {
    ...state,
    ...payload,
    schedule: schedule,
    startDate: now.plus({ days: payload.date }).toMillis(),
  };
};
const rehydrateData = (state: ICampaignPublish, payload: any) => {
  const newState = {
    ...state,
    ...payload.data,
  };
  return calculateSideEffects(newState);
};
const setMapCenter = (state: ICampaignPublish, payload: [number, number]) => {
  return {
    ...state,
    center: payload,
  };
};
const setMapZoom = (state: ICampaignPublish, payload: [number]) => {
  return {
    ...state,
    zoom: payload,
  };
};

const restartState = (state: ICampaignPublish, payload: any) => {
  return {
    ...initialState,
    locations: [],
    pph: [],
    schedule: JSON.parse(JSON.stringify(initialState.schedule)),
    formats: [],
    uuid: uuidv4(),
  };
};

const resetLocations = (state: ICampaignPublish, payload: any) => {
  let date = JWT.getJwtUser().role === 'admin' ? 0 : 2;

  const newState = {
    ...state,
    locations: [],
    schedule: [
      {
        startDate: now.plus({ days: date }).toMillis(),
        readOnly: false,
        title: '',
        type: ILocationSchedule.Hourly,
        isGlobal: true,
        uuid: 'global',
        isDefault: false,
        frequencies: [],
        pph: 0,
        locations: [
          {
            uuid: '1',
            reachCircuit: 1,
            duration: 1,
            acceptedDurations: [1],
            name: 'Global Schedule',
            restriction: [],
            restrictionsFormat: [],
            rt: 1,
            pC: false,
            frequencies: [
              {
                seconds: 1,
                label: '1',
                uuid: '1',
              },
            ],
            circuit: 1,
            pph: 1,
            minBookingHours: -1,
          },
        ],
        slots: [],
      },
    ],
  };
  return calculateSideEffects(newState);
};

const setMapLocations = (state: ICampaignPublish, payload: any) => {
  return {
    ...state,
    mapLocations: payload.mapLocations,
  };
};

const setCampaignLocations = (state: ICampaignPublish, payload: ILocationFull[]) => {
  let locations = state.locations ? [...state.locations] : [];
  let schedule: IScheduler[] = state?.schedule ? [...state?.schedule] : [];

  for (const location of payload) {
    if (!location.formatGroupId) location.formatGroupId = '0';
    locations.push({
      location: location,
      pph: location.campaignFrequency || 360,
      totalLocations: locations?.length ?? 0,
      reach: 0,
    });
  }
  const newState = {
    ...state,
    locations,
    toggleSlots: Math.random(),
    //  mapLocations: payload.mapLocations
  };
  schedule = schedule?.map(el => {
    if (el.uuid !== 'global') {
      el.isGlobal = true;
      return { ...el };
    } else {
      return { ...el, slots: [] };
    }
  });
  newState.slots = [];
  newState.schedule = schedule;

  return calculateSideEffects(newState);
};

const setCampaignLocation = (state: ICampaignPublish, payload: IListLocation) => {
  gtmAddToCart(payload.location);
  let locations = state.locations ? [...state.locations] : [];

  if (!payload.location.formatGroupId) payload.location.formatGroupId = '0';
  payload.addGlobalSlots = true;
  locations.push(payload);
  const removedItems = state.removedItems ? [...state.removedItems] : [];

  const newState = {
    ...state,
    removedItems: removedItems.filter(item => item !== payload.location.uuid),
    locations,
    toggleSlots: Math.random(),
    //  mapLocations: payload.mapLocations
  };

  newState.slots = [];

  return calculateSideEffects(newState);
};

const setAgregateData = (state: ICampaignPublish, payload: any) => {
  return {
    ...state,
    price: payload.price,
    reach: payload.reach,
  };
};

const setCredit = (
  state: ICampaignPublish,
  payload: {
    usedCredits: number;
    vatCredits: number;
    credit: number;
  }
) => {
  return {
    ...state,
    usedCredits: payload.usedCredits,
    vatCredits: payload.vatCredits,
    credit: payload.credit,
    //  mapLocations: payload.mapLocations
  };
};

const addFormats = (state: ICampaignPublish, payload: { formats: ICampaignFormat[] }) => {
  return {
    ...state,
    formats: payload.formats,
  };
};

const setMedia = (
  state: ICampaignPublish,
  payload: {
    type: any;
    formatId: string;
    media: any[];
  }
) => {
  const { formatId, media } = payload;

  const newState = {
    ...state,
    formats: state.formats.map(el => {
      if (el.uuid === formatId) {
        el.media = uniqObj([...el.media, ...media], 'hash');
      }
      return el;
    }),
  };

  return newState;
};

const setPercentageVoucher = (
  state: ICampaignPublish,
  payload: {
    coupon: string;
  }
) => {
  return {
    ...state,
    coupon: payload.coupon,
  };
};

const removePercentageVoucher = (state: ICampaignPublish) => {
  delete state.coupon;
  return {
    ...state,
  };
};

type IRemoveCampaignLocation = {
  mapLocations: string[];
};

const addToGlobal = (state: ICampaignPublish, payload: { uuid: string }) => {
  if (!state.schedule) return state;

  const scheduleIndex = state.schedule?.findIndex(el => el.uuid === payload.uuid);
  if (scheduleIndex === -1) {
    return state;
  }

  const schedule = state.schedule ? [...state.schedule] : [];
  let slots = schedule.filter(el => el.uuid === 'global')?.[0].slots;

  const currentSchedule = schedule[scheduleIndex];
  currentSchedule.slots = calculateSlots(currentSchedule, slots);

  currentSchedule.isGlobal = true;
  schedule[scheduleIndex] = currentSchedule;
  return {
    ...state,
    schedule: schedule,
    toggleSlots: Math.random(),
  };

  return state;
};

const linkAllToGlobal = (state: ICampaignPublish) => {
  if (!state.schedule) return state;

  const schedule = [...state.schedule];

  const globalSchedule = schedule.find(el => el.uuid === 'global');
  const globalSlots = globalSchedule ? globalSchedule.slots : [];

  schedule.forEach((currentSchedule, index) => {
    if (currentSchedule.uuid === 'global') return; 

    const updatedSlots = calculateSlots(currentSchedule, globalSlots);
    schedule[index] = {
      ...currentSchedule,
      slots: updatedSlots,
      isGlobal: true,
    };
  });

  return {
    ...state,
    schedule: schedule,
    toggleSlots: Math.random(), 
  };
};

const removeCampaignLocation = (state: ICampaignPublish, payload: IRemoveCampaignLocation) => {
  let deletedLocations = state.locations?.filter(el => {
    if (payload.mapLocations) return payload.mapLocations.includes(el.location.uuid);
    return false;
  });

  if (deletedLocations) gtmRemoveFromCart(deletedLocations[0].location);

  let locations = state.locations?.filter(el => {
    if (payload.mapLocations) return !payload.mapLocations.includes(el.location.uuid);
    return false;
  });
  let pphData = state.pph ? [...state.pph] : [];
  let campaignSchedule = state.schedule ? [...state.schedule] : [];
  const removedItems = state.removedItems ? [...state.removedItems] : [];

  if (deletedLocations)
    for (const item of deletedLocations) {
      if (pphData)
        for (const pph of pphData) {
          pph.locations = pph.locations.filter(el => el !== item.location.uuid);
        }
      removedItems.push(item.location.uuid);
      if (campaignSchedule) {
        for (const schedule of campaignSchedule) {
          const locationsNew = [];
          for (const location of schedule.locations) {
            if (location.uuid !== item.location.uuid) locationsNew.push(location);
          }
          schedule.locations = locationsNew;
        }
      }
    }
  pphData = pphData.filter(el => el.locations.length > 0);
  campaignSchedule = campaignSchedule.filter(el => el.locations.length > 0 || el.isGlobal);

  const newState = {
    ...state,
    removedItems: removedItems,
    pph: pphData,
    schedule: campaignSchedule,
    toggleSlots: Math.random(),
    toggleListOnlyBook: Math.random(),
    locations,
  };
  return calculateSideEffects(newState);
};
type IPph = {
  pph: string;
  locations: string[];
  schedule: string;
};

const setPPh = (state: ICampaignPublish, payload: IPph) => {
  let statePph: ICampaignPublishPph[] = [];
  const publishSchedule = state.schedule ? [...state.schedule] : [];
  const locations = state.locations ? [...state.locations] : [];

  for (const schedule of publishSchedule) {
    if (schedule.uuid === payload.schedule) {
      schedule.pph = parseInt(payload.pph, 10);

      for (const location of schedule.locations) {
        location.pph = parseInt(payload.pph, 10);
        for (const stateLocation of locations) {
          if (stateLocation.location.uuid === location.uuid) {
            stateLocation.pph = parseInt(payload.pph, 10);
          }
        }
      }
    }
  }
  return {
    ...state,
    schedule: publishSchedule,
    toggleSlots: Math.random(),
    locations: locations,
    //  mapLocations: payload.mapLocations
  };
};

type ICreatePPh = {
  locationsIds: string[];
  index: number;
};
const createPPh = (state: ICampaignPublish, payload: ICreatePPh) => {
  let statePph: ICampaignPublishPph[] = [];
  if (state.pph) {
    statePph = [...state.pph];

    const oldLocations = statePph[payload.index].locations.filter(el => {
      return !payload.locationsIds.includes(el);
    });
    statePph[payload.index].locations = oldLocations;
    state.pph = statePph;

    let uuid = uuidv4();
    let p: ICampaignPublishPph = {
      pph: statePph[payload.index].pph,
      locations: payload.locationsIds,
      otherPph: statePph[payload.index].otherPph,
      pphGroup: uuid,
    };
    statePph.push(p);
  } else {
    statePph = [];
  }
  return {
    ...state,
    pph: statePph,
    toggleSlots: Math.random(),
    //  mapLocations: payload.mapLocations
  };
};

const calculateSideEffects = (state: ICampaignPublish) => {
  return {
    ...state,
    pph: state.locations ? calculatePph(state, state.locations) : [],
    schedule: state.locations ? calculateSchedule(state, state.locations) : initialState.schedule,
    formats: state.locations ? calculateFormats(state) : [],
  };
};
const sortbyseconds = (a: ILocationFrequency, b: ILocationFrequency) => {
  return a.seconds < b.seconds ? -1 : a.seconds > b.seconds ? 1 : 0;
};

const addMultipledMediaToFormat = (
  state: ICampaignPublish,
  payload: {
    files: IMediaUpload[];
    uuid: string;
  }
) => {
  let formats = [...state.formats].map(el => {
    if (el.uuid === payload.uuid) {
      for (const file of payload.files) {
        const index = el.media.indexOf(file);
        if (index < 0) el.media.push(file);
      }
    }
    return el;
  });
  return {
    ...state,
    toggleMedia: Math.random(),
    formats: formats,
  };
};

const addMediaToFormat = (
  state: ICampaignPublish,
  payload: {
    file: IMediaUpload;
    uuid: string;
  }
) => {
  let formats = [...state.formats].map(el => {
    if (el.uuid === payload.uuid) {
      const index = el.media.indexOf(payload.file);
      if (index < 0) el.media.push(payload.file);
    }
    return el;
  });
  return {
    ...state,
    toggleMedia: Math.random(),
    formats: formats,
  };
};

const addRulesToMedia = (
  state: ICampaignPublish,
  payload: {
    rulesC: IRulesMediaCard[];
    uuid: string;
    mediaId: string;
  }
) => {
  const formats = [...state.formats];
  for (const format of formats) {
    if (format.uuid === payload.uuid) {
      for (const media of format.media) {
        if (media.uid === payload.mediaId) {
          media.rules = payload.rulesC;
        }
      }
    }
  }
  return {
    ...state,
    toggleMedia: Math.random(),
    formats: formats,
  };
};

const removeMediaToFormat = (
  state: ICampaignPublish,
  payload: {
    fileUuid: string;
    formatUuid: string;
  }
) => {
  let formats = [...state.formats].map(el => {
    if (payload.formatUuid === el.uuid) {
      let media = el.media.filter(e => {
        return e.uid !== payload.fileUuid;
      });
      el.media = media;
    }
    return el;
  });
  return {
    ...state,
    toggleMedia: Math.random(),
    formats: formats,
  };
};

const calculateFormats = (state: ICampaignPublish) => {
  let newFormats: ICampaignFormat[] = [...state.formats];
  //  let formats = [];
  if (state.locations) {
    for (const el of state.locations) {
      const resolution = `${el.location.horRez}x${el.location.vertRez}`;
      const ratio = (el.location.horRez / el.location.vertRez).toFixed(2);
      const formatContentType = el.location.contentType;

      const foundIndex = newFormats.findIndex((format: ICampaignFormat) => {
        let formatRezolution = `${format.resolution[0]}x${format.resolution[1]}`;
        if (formatRezolution === resolution && `${format.groupId}` === `${el.location.formatGroupId}`) {
          const foundDuration = el.location.acceptedDurations.filter(item => {
            return format.durations.includes(item);
          });
          if (foundDuration.length > 0 && formatContentType.includes(format.contentType)) {
            return true;
          }
        }
        return false;
      });
      if (foundIndex === -1) {
        const d = el.location.acceptedDurations.sort((a, b) => (a > b ? 1 : -1));
        const newFormat: ICampaignFormat = {
          resolution: [el.location.horRez, el.location.vertRez],
          uuid: uuidv4(),
          media: [],
          rt: el.location.rt,
          groupId: el.location.formatGroupId,
          isMultiScreen: el.location.isMultiScreen,
          multiScreenUrl: el.location.multiScreenUrl,
          ratio: ratio,
          locations: [el.location.uuid],
          locationsName: [el.location.n],
          selectedDuration: 0,
          contentType: formatContentType.includes(ILocationContentType.Video)
            ? ILocationContentType.Video
            : ILocationContentType.Static,
          durations: [...d],
        };
        newFormats.push(newFormat);
      } else {
        const foundFormatLocation = [...newFormats[foundIndex].locations];
        const foundFormatLocationName = newFormats[foundIndex].locationsName
          ? [...newFormats[foundIndex].locationsName]
          : [el.location.n];
        if (!foundFormatLocation.includes(el.location.uuid)) {
          foundFormatLocation.push(el.location.uuid);
        }
        if (!foundFormatLocationName.includes(el.location.n)) {
          foundFormatLocationName.push(el.location.n);
        }
        newFormats[foundIndex].locations = foundFormatLocation;
        newFormats[foundIndex].locationsName = foundFormatLocationName;
      }
    }
  }
  const newFormatData = newFormats
    .map((el: ICampaignFormat) => {
      let setLocation: string[] = [];
      let setLocationNames: string[] = [];
      const formatResolution = `${el.resolution[0]}x${el.resolution[1]}`;
      const contentType = el.contentType;
      let elDuration: number[] = [];
      if (state.locations)
        state.locations.forEach(location => {
          const resolutionScreen = `${location.location.horRez}x${location.location.vertRez}`;

          if (
            `${location.location.formatGroupId}` === `${el.groupId}` &&
            resolutionScreen === formatResolution &&
            location.location.contentType.includes(contentType)
          ) {
            const foundDuration = location.location.acceptedDurations.filter(item => {
              return el.durations.includes(item);
            });
            if (foundDuration.length > 0) {
              elDuration = mostFrequentElements([...elDuration, ...location.location.acceptedDurations]);
              setLocation.push(location.location.uuid);
              setLocationNames.push(location.location.n);
            }
          }
        });
      el.locations = setLocation;
      el.locationsName = setLocationNames;
      el.durations = elDuration;
      if (el.selectedDuration === 0) el.selectedDuration = el.durations[0];
      else {
        const hasDuration = el.durations.includes(el.selectedDuration);
        if (!hasDuration) el.selectedDuration = el.durations[0];
      }
      return el;
    })
    .filter((format: ICampaignFormat) => {
      return format.locations.length > 0;
    });

  return newFormatData;
};

const mostFrequentElements = (arr: any[]) => {
  const elementCounts = new Map<any, number>();
  let maxCount = 0;
  let maxElements: any[] = [];

  for (let i = 0; i < arr.length; i++) {
    const element = arr[i];
    let count = elementCounts.get(element) || 0;
    count++;
    elementCounts.set(element, count);

    if (count > maxCount) {
      maxCount = count;
      maxElements = [element];
    } else if (count === maxCount) {
      maxElements.push(element);
    }
  }

  return maxElements;
};

const batchUpdatePPh = (state: ICampaignPublish, payload: any) => {
  const locations = state.locations ? [...state.locations] : [];
  const newLocations = locations.map(location => {
    if (payload.locationUuids.includes(location.location.uuid)) {
      location.pph = payload.pph;
    }
    return location;
  });
  const schedule = state.schedule ? [...state.schedule] : [];
  const newSchedule = schedule.map(el => {
    let foundLocation = false;
    const newLocations = el.locations.map(location => {
      if (payload.locationUuids.includes(location.uuid)) {
        location.pph = payload.pph;
        foundLocation = true;
      }
      return location;
    });

    if (foundLocation) {
      el.pph = payload.pph;
    }
    el.locations = newLocations;
    return el;
  });
  const z = {
    ...state,
    schedule: newSchedule,
    locations: newLocations,
    toggleSlots: Math.random(),
  };
  return z;
};

const setScheduleSlots = (state: ICampaignPublish, payload: any) => {
  // const setScheduleSlots = (state: ICampaignPublish, payload: IScheduleSchedule[]) => {
  const publishSchedule = state.schedule ? [...state.schedule] : [];
  let nSlots = [];

  for (const item of publishSchedule) {
    let [foundSchedule] = payload.filter((el: any) => {
      return el.uuid === item.uuid;
    });
    if (foundSchedule) {
      nSlots.push(...foundSchedule.slots);
      item.slots = foundSchedule.slots;
      item.isGlobal = foundSchedule.isGlobal;
    }
  }
  console.timeEnd('setScheduleSlots');

  let slots = state.slots ? [...state.slots] : [];
  return {
    ...state,
    slots: Array.from(new Set(slots.concat(nSlots))),
    toggleSlots: Math.random(),
    schedule: publishSchedule,
    //  mapLocations: payload.mapLocations
  };
};

type IMediaDuration = {
  value: number;
  index: number;
};

const setMediaDuration = (state: ICampaignPublish, payload: IMediaDuration) => {
  const formats = state.formats;
  try {
    formats[payload.index].selectedDuration = payload.value;
    const newState = {
      ...state,
      recalculateReach: Math.random(),
      formats: formats,
    };
    return newState;
  } catch (error) {
    console.log(error);
    return state;
  }
};

type IUpdateVideoCardDuration = {
  formatId: string;
  mediaId: string;
  duration: number;
};

const updateVideoCardDuration = (state: ICampaignPublish, payload: IUpdateVideoCardDuration) => {
  const { formatId, mediaId, duration } = payload;
  const newState = {
    ...state,
    formats: state.formats.map((format: any) => {
      if (format.uuid === formatId) {
        format.media = format.media.map((media: any) => {
          if (media.uid === mediaId) {
            media.duration = duration;
          }
          return media;
        });
      } else {
      }

      return format;
    }),
  };

  return newState;
};

// type ICreateSchedule = {
//   locationsIds: string[];
//   uuid: string;
//   pph: string;
// };

// const createSchedule = (state: ICampaignPublish, payload: ICreateSchedule) => {
//   const publishSchedule = state.schedule ? [...state.schedule] : [];
//   const locations = state.locations?.filter(el => {
//     return payload.locationsIds.includes(el.location.uuid);
//   });
//   if (locations && locations?.length > 0) {
//     let uuid = uuidv4();
//     const newSchedule: IScheduler = {
//       startDate: state.startDate,
//       readOnly: false,
//       title: '',
//       type: locations[0].location.acceptedSchedules,
//       uuid: uuid,
//       isDefault: false,
//       frequencies: [],
//       pph: locations[0].pph,
//       locations: locations.map(el => {
//         return {
//           uuid: el.location.uuid,
//           name: el.location.n,
//           rt: el.location.rt,
//           pC: el.location.pC,
//           circuit: el.location.circuit,
//           frequencies: el.location.frequencies,
//           pph: el.pph,
//           duration: el.location.acceptedDurations.sort((a, b) => a - b)[0],
//           acceptedDurations: el.location.acceptedDurations.sort((a, b) => a - b),
//           restriction: el.location.scheduleRestriction,
//           minBookingHours: parseInt(el.location.minBookingHours),
//         };
//       }),
//       slots: [],
//     };
//     const set = new Set();
//     const arrayOfComplexObjects = [];
//     for (const location of newSchedule.locations) {
6; //       for (const obj of location.frequencies) {
//         if (!set.has(obj.uuid)) {
//           set.add(obj.uuid);
//           arrayOfComplexObjects.push(obj);
//         }
//       }
//     }
//     newSchedule.frequencies = arrayOfComplexObjects;

//     for (const item of publishSchedule) {
//       if (item.uuid === payload.uuid) {
//         item.locations = item.locations.filter(el => {
//           return !payload.locationsIds.includes(el.uuid);
//         });
//       }
//     }

//     publishSchedule.push(newSchedule);
//   }
//   return {
//     ...state,
//     schedule: publishSchedule,
//     //  mapLocations: payload.mapLocations
//   };
// };

const calculateSchedule = (state: ICampaignPublish, locations: IListLocation[]) => {
  const publishSchedule = state.schedule || [];
  // const locationsTest = state.locations?.map(el => el.location.uuid);
  // console.log('locationsTest', locationsTest);
  let hasGlobalRestrictions = 1;
  locations.map((el: IListLocation) => {
    // console.log('eeee', el);
    let foundScheduleIndex = -1;
    const foundSchedule = publishSchedule.filter((schedule, index) => {
      const hasLocation = schedule.locations.filter(scheduleEl => {
        return scheduleEl.uuid === el.location.uuid;
      });

      return hasLocation.length > 0;
    });
    if (foundSchedule.length === 0) {
      let uuid = uuidv4();

      const restrictions: any[] = [];
      el.location.scheduleRestriction?.forEach(dayObject => {
        dayObject.generatedPrice?.forEach((value, index) => {
          if (value === '0') {
            restrictions.push(dayObject.day + '_' + index);
          }
        });
      });

      const newSchedule: IScheduler = {
        startDate: state.startDate,
        readOnly: false,
        title: '',
        type: el.location.acceptedSchedules,
        uuid: uuid,
        isGlobal: true,
        pph: el.pph.toString() !== '0' ? el.pph : 3600 / parseInt(el.location.frequencies[0].seconds.toString(), 10),
        frequencies: el.location.frequencies,
        isDefault: true,
        locations: [
          {
            uuid: el.location.uuid,
            name: el.location.n,
            rt: el.location.rt,
            pC: el.location.pC,
            reachCircuit: el.location.reachCircuit,
            pph: el.pph,
            frequencies: el.location.frequencies,
            circuit: el.location.circuit,
            duration: el.location.acceptedDurations ? el.location.acceptedDurations.sort((a, b) => a - b)[0] : 10,
            acceptedDurations: el.location.acceptedDurations
              ? el.location.acceptedDurations.sort((a, b) => a - b)
              : [10],
            restriction: el.location.scheduleRestriction,
            restrictionsFormat: restrictions,
            minBookingHours: parseInt(el.location.minBookingHours),
          },
        ],
        slots: [],
      };

      if (el.addGlobalSlots) {
        const globalSchedule = publishSchedule.filter(el => el.uuid === 'global');
        const s = calculateSlots(newSchedule, globalSchedule[0].slots);
        newSchedule.slots = s;
      }

      if (newSchedule.locations[0].restrictionsFormat.length === 0) {
        hasGlobalRestrictions = 0;
      }

      const globalSchedule = publishSchedule.filter((el: any) => {
        return el.locations[0] && el.locations[0].uuid === '1' ? true : false;
      });

      if (hasGlobalRestrictions) {
        const allRestrictions = [
          ...globalSchedule[0].locations[0].restrictionsFormat,
          ...newSchedule.locations[0].restrictionsFormat,
        ];
        const commonRestrictionsFormat = mostFrequentElements(allRestrictions);
        const commonRestrion = formatArray(commonRestrictionsFormat);

        globalSchedule[0].locations[0].restrictionsFormat = commonRestrictionsFormat;
        globalSchedule[0].locations[0].restriction = commonRestrion;
      } else {
        globalSchedule[0].locations[0].restrictionsFormat = [];
        globalSchedule[0].locations[0].restriction = [];
      }

      publishSchedule.push(newSchedule);
    } else {
      const found = foundSchedule[0].locations.find(f => f.uuid === el.location.uuid);
      if (found) {
        const restrictions: any[] = [];
        found.restriction?.forEach(dayObject => {
          dayObject.generatedPrice?.forEach((value, index) => {
            if (value === '0') {
              restrictions.push(dayObject.day + '_' + index);
            }
          });
        });
        found.restrictionsFormat = restrictions;

        if (found.restrictionsFormat.length === 0) {
          hasGlobalRestrictions = 0;
        }

        const globalSchedule = publishSchedule.filter((el: any) => {
          return el.locations[0] && el.locations[0].uuid === '1' ? true : false;
        });

        if (hasGlobalRestrictions) {
          const allRestrictions = globalSchedule[0].locations[0].restrictionsFormat
            ? [...globalSchedule[0].locations[0].restrictionsFormat, ...found.restrictionsFormat]
            : [...found.restrictionsFormat];
          const commonRestrictionsFormat = mostFrequentElements(allRestrictions);
          const commonRestrion = formatArray(commonRestrictionsFormat);

          globalSchedule[0].locations[0].restrictionsFormat = commonRestrictionsFormat;
          globalSchedule[0].locations[0].restriction = commonRestrion;
        } else {
          if (globalSchedule[0]) {
            globalSchedule[0].locations[0].restrictionsFormat = [];
            globalSchedule[0].locations[0].restriction = [];
          }
        }
      }
    }
    // } else {
    //   const found = foundSchedule[0].locations.find((f) => f.uuid === el.location.uuid)
    //   if (!found) {
    //     foundSchedule[0].locations.push({
    //       uuid: el.location.uuid,
    //       name: el.location.n,
    //       rt: el.location.rt,
    //       pph: el.pph,
    //       frequencies: el.location.frequencies,
    //       pC: el.location.pC,
    //       circuit: el.location.circuit,
    //       duration: el.location.acceptedDurations.sort((a, b) => a - b)[0],
    //       acceptedDurations: el.location.acceptedDurations.sort((a, b) => a - b),
    //       restriction: el.location.scheduleRestriction
    //     })
    //     let frequencies = foundSchedule[0].frequencies
    //     frequencies.push(...el.location.frequencies)
    //     const set = new Set()
    //     const arrayOfComplexObjects = []
    //     for (const obj of frequencies) {
    //       if (!set.has(obj.uuid)) {
    //         set.add(obj.uuid)
    //         arrayOfComplexObjects.push(obj)
    //       }
    //     }
    //     publishSchedule[foundScheduleIndex].locations = foundSchedule[0].locations
    //     publishSchedule[foundScheduleIndex].frequencies = arrayOfComplexObjects
    //   }
    // }
  });
  const scheduleWithDays = publishSchedule.filter((el: IScheduler) => {
    return el.type !== ILocationSchedule.Daily && el.uuid !== 'global';
  });

  return publishSchedule
    .filter(el => el.locations.length > 0 || el.uuid === 'global')
    .map((el: IScheduler) => {
      if (el.uuid === 'global') {
        el.type = scheduleWithDays.length === 0 ? ILocationSchedule.Daily : ILocationSchedule.Hourly;
      }
      return el;
    });
};

const formatArray = (arr: any) => {
  let result: any[] = [];
  let uniqueDays = [...new Set(arr.map((item: any) => item.split('_')[0]))];

  uniqueDays.forEach(day => {
    let values = arr.filter((item: any) => item.startsWith(day)).map((item: any) => parseInt(item.split('_')[1]));

    let generatedPrice = [];
    for (let i = 0; i < 24; i++) {
      if (values.includes(i)) {
        generatedPrice.push('0');
      } else {
        generatedPrice.push('1');
      }
    }
    result.push({ day, generatedPrice });
  });

  return result;
};

const calculatePph = (state: ICampaignPublish, locations: IListLocation[]) => {
  const pphData = state.pph || [];
  locations.map((el: IListLocation) => {
    if (!el.location.pphGroup) {
      const foundPph = pphData.filter(pph => {
        let toReturn = false;
        if (pph.pph?.toString() === el.pph.toString()) {
          let a = pph.otherPph.sort(sortbyseconds);
          let b = el.location.frequencies.sort(sortbyseconds);

          let hasMatch = true;
          if (a.length === b.length)
            for (let i = 0; i < a.length; ++i) {
              if (a[i].seconds !== b[i].seconds) hasMatch = false;
            }
          if (hasMatch) {
            toReturn = true;
          }
        }
        return toReturn;
      });
      if (foundPph.length > 0) {
        foundPph[0].locations.push(el.location.uuid);
      } else {
        let uuid = uuidv4();
        let p: ICampaignPublishPph = {
          pph: el.pph,
          locations: [el.location.uuid],
          otherPph: el.location.frequencies,
          pphGroup: uuid,
        };
        pphData.push(p);
      }
    }
  });
  for (let item of pphData) {
    let locationsNew = new Set(item.locations);
    item.locations = Array.from(locationsNew);
  }
  return pphData;
};

export const reducer = (state: any, action: IPublishAction) => {
  switch (action.type) {
    case IPublishType.setBasic:
      return setBasic(state, action.payload);
    case IPublishType.setMapLocation:
      return setMapLocations(state, action.payload);
    case IPublishType.addLocation:
      return setCampaignLocation(state, action.payload);
    case IPublishType.addAll:
      return setCampaignLocations(state, action.payload);
    case IPublishType.addToGlobal:
      return addToGlobal(state, action.payload);
    case IPublishType.linkAllToGlobal:
      return linkAllToGlobal(state);
    case IPublishType.removeLocation:
      return removeCampaignLocation(state, action.payload);
    case IPublishType.setPph:
      return setPPh(state, action.payload);
    case IPublishType.updatePph:
      return batchUpdatePPh(state, action.payload);

    case IPublishType.createPph:
      return createPPh(state, action.payload);
    // case IPublishType.createSchedule:
    //   return createSchedule(state, action.payload);
    case IPublishType.setScheduleSlots:
      return setScheduleSlots(state, action.payload);
    case IPublishType.addMediaToFormat:
      return addMediaToFormat(state, action.payload);
    case IPublishType.adMultipledMediaToFormat:
      return addMultipledMediaToFormat(state, action.payload);
    case IPublishType.removeMediaToFormat:
      return removeMediaToFormat(state, action.payload);
    case IPublishType.addRulesToMedia:
      return addRulesToMedia(state, action.payload);
    case IPublishType.setAgregateData:
      return setAgregateData(state, action.payload);
    case IPublishType.setCredit:
      return setCredit(state, action.payload);
    case IPublishType.setPercentageVoucher:
      return setPercentageVoucher(state, action.payload);
    case IPublishType.removePercentageVoucher:
      return removePercentageVoucher(state);
    case IPublishType.restartState:
      return restartState(state, action.payload);
    case IPublishType.setMapCenter:
      return setMapCenter(state, action.payload);
    case IPublishType.setMapZoom:
      return setMapZoom(state, action.payload);
    case IPublishType.rehydrate:
      return rehydrateData(state, action.payload);
    case IPublishType.setMediaDuration:
      return setMediaDuration(state, action.payload);
    case IPublishType.addFormats:
      return addFormats(state, action.payload);
    case IPublishType.setMedia:
      return setMedia(state, action.payload);
    case IPublishType.updateVideoCardDuration:
      return updateVideoCardDuration(state, action.payload);
    case IPublishType.resetLocations:
      return resetLocations(state, action.payload);
  }
};
function uniqObj(arr: any[], property: string): IMediaUpload[] {
  let cachedObject: any = {};
  arr.forEach((item: any) => {
    cachedObject[item[property]] = item;
  });
  arr = Object.values(cachedObject);
  return arr;
}
