import React, { useEffect, useRef } from 'react';
import * as Yup from 'yup';
import { useIntl, FormattedMessage } from 'react-intl';

import { PublishContext } from 'context/publish/publish.provider';
import { MapContext } from 'context/publish/map.provider';
import ReactTooltip from 'react-tooltip';
import { ILocationStatus } from 'interface/ILocation';

import { useRouter } from 'next/router';

import {
  BreadCrumbs,
  FooterMobile,
  PublishMapFilter,
  PublishMapSection,
  PublishMapList,
  Dialog,
} from 'components/common/molecules';
import { Icon } from '@iconify/react-with-api';
import { IOptions } from 'interface/ISelect';
import { CAMPAIGNS, JWT } from 'api/';
import { gtmCheckout1, gtmCheckout2 } from 'api/gtm/gtm';
import { string } from '@amcharts/amcharts4/core';
import dynamic from 'next/dynamic';
const MapTest = dynamic(() => import('components/common/molecules/MapTest'), {
  ssr: false,
});
import { IPublishType } from 'context/publish/publish.reducer';
import { debounce } from 'lodash';

type IProps = {
  locationsList?: any;
};

const PublishMap: React.FC<IProps> = ({ locationsList }) => {
  const router = useRouter();
  let user = JWT.getJwtUser();

  const [onlyBook, setOnlyBook] = React.useState<string>('1');
  const [comingSoon, setComingSoon] = React.useState<string>('1');
  const [hasImage, setHasImage] = React.useState<string>('1');

  const [dialogOpen, setDialogOpen] = React.useState<boolean>(false);
  const soldOutLocations = useRef<string[]>([]);
  const soldOutLocationsIds = useRef<string[]>([]);

  type PrimaryButtonProps = {
    buttonText: string;
    className?: string;
    dataCy?: string;
    action: () => void;
  };

  useEffect(() => {
    const only_book_ls = localStorage.getItem('only_book');
    setOnlyBook(only_book_ls === '1' ? '1' : '0');
    
    const coming_soon_ls = localStorage.getItem('coming_soon');
    setComingSoon(coming_soon_ls === '1' ? '1' : '0');

    const has_image_ls = localStorage.getItem('has_image');
    setHasImage(has_image_ls === '0' ? '0' : '1');

    gtmCheckout1();
    if (!publishState.name || publishState.industry?.length === 0) {
      router.push('/publish/basic');
    }
    return () => {};
  }, []);

  useEffect(() => {}, [locationsList]);

  const { publishState, dispatch } = React.useContext(PublishContext);
  useEffect(() => {
    CAMPAIGNS.saveCampaignDraft({ ...publishState, maxStep: 3 }).catch(err => console.log(err));
    return () => {};
  }, []);

  const saveDraft = async () => {
    return CAMPAIGNS.saveCampaignDraft({ ...publishState, maxStep: 3 });
  };
  const debouncedSaveDraft = debounce(saveDraft, 10000);

  useEffect(() => {
    debouncedSaveDraft()?.catch(err => console.log(err));
  }, [publishState.locations?.length]);

  const backFn = () => {
    router.push('/publish/basic/?activDraft=' + publishState.uuid);
  };
  const nextFn = () => {
    soldOutLocations.current = [];
    soldOutLocationsIds.current = [];
    if (publishState.locations && publishState.locations?.length > 0) {
      let hasUnavailableLocations = 0;
      publishState.locations.forEach(l => {
        const location = l.location;

        if (
          (location.country === 'Romania' && location.onboardingStatus !== ILocationStatus.live) ||
          !location.stock ||
          location.stock.color === 'soldout'
        ) {
          hasUnavailableLocations++;
          soldOutLocationsIds.current.push(location.uuid);
          soldOutLocations.current.push(location.n);
        }
        if (hasUnavailableLocations)
          // eslint-disable-next-line no-useless-return
          return;
      });
      if (hasUnavailableLocations) {
        setDialogOpen(true);
      } else {
        gtmCheckout2();
        router.push(`/publish/schedule/?campaignId=${publishState.uuid}`);
      }
    }
  };

  const primaryButton: PrimaryButtonProps = {
    buttonText: 'Continue',
    dataCy: 'warnContinue',
    action: () => {
      if (soldOutLocations.current.length > 0 && user.role !== 'admin') {
        dispatch({
          type: IPublishType.removeLocation,
          payload: {
            mapLocations: soldOutLocationsIds.current,
          },
        });
      }
      if (soldOutLocationsIds.current?.length >= (publishState.locations?.length || 0) && user.role !== 'admin') {
        setDialogOpen(false);
        return;
      }
      if (publishState.locations && publishState.locations?.length > 0) {
        gtmCheckout2();
        router.push(`/publish/schedule/?campaignId=${publishState.uuid}`);
      }
    },
  };
  const secondaryButton: PrimaryButtonProps = {
    buttonText: 'Cancel',
    dataCy: 'warnCancel',
    action: () => {
      setDialogOpen(false);
    },
  };

  const displayTextForSoldOutLocations = (locations: string[]) => {
    let displayText = 'Locations Impacted: <br />';
    if (locations.length > 3) {
      displayText = `${displayText}${locations.slice(0, 3).join(', ')} and ${locations.length - 3} more locations`;
    } else {
      displayText = `${displayText}${locations.join(', ')}`;
    }
    return displayText;
  };

  return (
    <>
      {dialogOpen ? (
        <Dialog
          key="1"
          title={
            user.role !== 'admin' ? 'Sold Out Items Detected in Your Shopping Basket' : 'Book on Sold-Out Locations?'
          }
          primaryButton={primaryButton}
          secondaryButton={secondaryButton}
          boldText={displayTextForSoldOutLocations(soldOutLocations.current)}
          description={
            user.role !== 'admin'
              ? 'Meanwhile, certain locations in your basket are now sold out. Should you continue, these will be automatically deleted from your basket'
              : `Are you sure you want to book a campaign on Sold-Out locations? PRICES may be HIGHER on those location when creative plays!`
          }
        />
      ) : (
        ''
      )}
      <div className="lg:static z-10 page-section">
        <MapTest locationsList={locationsList}></MapTest>
        {!publishState.locations?.length && (
          <ReactTooltip id="footer-tooltip" className="custom-tooltip" place="top" offset={{ left: 20 }}>
            <div className="flex flex-col preview-media-tooltip">
              <div className="flex row-tooltip-container">
                <div className="excl-mark pulse-disclaimer mr-2">!</div>
                <span className="condition-text-tooltip">No location booked</span>
              </div>
            </div>
          </ReactTooltip>
        )}
        <FooterMobile
          backFn={backFn}
          nextFn={nextFn}
          mainPage="Dashboard"
          links={[publishState.name]}
          back="1"
          next="1"
          hasNext={publishState.locations && publishState.locations?.length > 0}
        />
      </div>
    </>
  );
};
export default PublishMap;
