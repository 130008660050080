import React, { useEffect } from 'react';
import { IOptions } from 'interface/ISelect';
import { useRouter } from 'next/router';
import { getComingSoon, getHasImage, getOnlyBook } from '@utils/map';
import { useNetworks } from '@utils/networks';
import { LocationMapInfoProperty } from './publish/map.provider';

const useMapState = () => {
  const router = useRouter();
  const [reloadItems, setReloadItems] = React.useState<number>(0);
  const [clearFilters, setClearFilters] = React.useState<number>(0);
  const [clearFiltersTrigger, setClearFiltersTrigger] = React.useState<number>(0);
  const [updateFilters, setUpdateFilters] = React.useState<number>(0);
  const [searchText, setSearchText] = React.useState<string>(router?.query?.search ? String(router.query.search) : '');
  const [center, setCenter] = React.useState<[number, number]>([0, 0]);
  const [view, setView] = React.useState<string>(router?.query?.view ? String(router.query.view) : 'Half');

  const [searchTags, setSearchTags] = React.useState<IOptions[]>([]);

  React.useEffect(() => {
    if (router?.query?.networkId) {
      const fetchData = async () => {
        const values = String(router.query.networkId).split(',');
        const options = await Promise.all(
          values.map(async value => {
            // const label1 = await useNetworks().getNetworkLabelById(Number(value));
            return { label: '', value: value };
          })
        );
        setSearchTags(options);
      };
      fetchData();
    }
  }, [router?.query?.networkId]);

  const [onlyBook, setOnlyBook] = React.useState<string>(router?.query?.onlyBook === 'true' ? '1' : '0');
  const [comingSoon, setComingSoon] = React.useState<string>(router?.query?.onlyAvailable === 'true' ? '0' : '1');
  const [hasImage, setHasImage] = React.useState<string>(router?.query?.hasPictures === 'true' ? '0' : '1');
  const [searchCity, setSearchCity] = React.useState<string>(router?.query?.city ? String(router.query.city) : '');
  const [searchPrices, setSearchPrices] = React.useState<[number, number]>([
    //done
    router.query.minPrice ? Number(router.query.minPrice) : 0,
    router.query.maxPrice ? Number(router.query.maxPrice) : 3500,
  ]);

  const [loading, setLoading] = React.useState<boolean>(false);
  const [zoom, setZoom] = React.useState<[number]>([2]);
  const [locationType, setLocationType] = React.useState<string[]>( //done
    router.query.locationType ? String(router?.query?.locationType).split(',') : []
  );
  const [venueType, setVenueType] = React.useState<string[]>( //done
    router.query.venueType ? String(router?.query?.venueType).split(',') : []
  );
  const [totalItems, setTotalItems] = React.useState<number>(0);
  const [page, setPage] = React.useState<string>(router?.query?.page ? String(router?.query?.page) : '1'); //done
  const [bbox, setBbox] = React.useState<any>(); //done
  const [selectedLocations, setSelectedLocations] = React.useState<LocationMapInfoProperty[]>([]);

  useEffect(() => {
    if (
      router?.query?.topLeftLon &&
      router?.query?.topLeftLat &&
      router?.query?.bottomRightLon &&
      router?.query?.bottomRightLat
    ) {
      localStorage.setItem('routerNavigationParams', 'true');
    } else {
      localStorage.removeItem('routerNavigationParams');
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('only_book', onlyBook);
    localStorage.setItem('coming_soon', comingSoon);
    localStorage.setItem('has_image', hasImage);

    const obj = {
      onlyBook: getOnlyBook(),
      showUnavailable: getComingSoon(),
      showWithoutPictures: getHasImage(),
      locationType: locationType,
      venueType: venueType,
      searchText: searchText,
      searchTags: searchTags.map(tag => tag.value),
      searchPrices: searchPrices,
      searchCity: searchCity,
      view: view,
      page: page,
    };

    localStorage.setItem('searchMinPrice', String(searchPrices[0]));
    localStorage.setItem('searchMaxPrice', String(searchPrices[1]));
    localStorage.setItem('searchTags', JSON.stringify(searchTags));
    localStorage.setItem('venueType', JSON.stringify(venueType));
    localStorage.setItem('searchText', String(searchText));

    const query = { ...router.query };
    query.page = '1';
    setPage('1');
    if (obj.searchText) {
      query.search = String(obj.searchText);
    } else {
      delete query['search'];
    }

    query.onlyBook = String(obj.onlyBook);
    query.onlyAvailable = String(!obj.showUnavailable);
    query.hasPictures = String(!obj.showWithoutPictures);

    if (bbox) {
      query.topLeftLon = bbox[0].toFixed(5);
      query.topLeftLat = bbox[1].toFixed(5);
      query.bottomRightLon = bbox[2].toFixed(5);
      query.bottomRightLat = bbox[3].toFixed(5);
    }

    if (obj.locationType.length > 0) {
      query.locationType = String(obj.locationType.join(','));
    } else {
      delete query['locationType'];
    }

    if (obj.venueType.length > 0) {
      query.venueType = String(obj.venueType.join(','));
    } else {
      delete query['venueType'];
    }

    if (obj.searchTags.length > 0) {
      query.networkId = String(obj.searchTags.join(','));
    } else {
      delete query['networkId'];
    }

    if (obj.view) {
      query.view = obj.view;
    } else {
      delete query['view'];
    }

    query.minPrice = String(obj.searchPrices[0]);
    query.maxPrice = String(obj.searchPrices[1]);
    // query.city = String(obj.searchCity);

    const newUrl = {
      pathname: router.pathname,
      query: query,
    };
    if (router.asPath.includes('publish/select')) {
      router.push(newUrl);
      setLoading(true);
    }
  }, [reloadItems]);

  useEffect(() => {
    if (clearFilters !== 0) {
      setOnlyBook('0');
      setComingSoon('0');
      setHasImage('1');
      setCenter([0, 0]);
      setZoom([2]);
      setSearchText('');
      setSearchCity('');
      // setLocationType([]),
      setVenueType([]);
      setSearchTags([]);
      setTotalItems(0);
      setSearchPrices([0, 3500]);
      setLoading(true);
      setPage('1');
      // setBbox([0,0,0,0]),
      setView('Half');

      localStorage.removeItem('coming_soon');
      localStorage.removeItem('has_image');
      localStorage.removeItem('searchMinPrice');
      localStorage.removeItem('searchMaxPrice');
      localStorage.removeItem('searchTags');
      localStorage.removeItem('venueType');
      localStorage.removeItem('searchCheckbox');
      localStorage.removeItem('searchText');
      // setReloadItems(Math.random());
    }
  }, [clearFilters]);

  useEffect(() => {
    if (clearFiltersTrigger !== 0) {
      setOnlyBook('0');
      setComingSoon('0');
      setHasImage('1');
      // setReloadItems(1);
      setCenter([0, 0]);
      setZoom([2]);
      setSearchText('');
      setSearchCity('');
      setLocationType([]);
      setVenueType([]);
      setSearchTags([]);
      setTotalItems(0);
      setSearchPrices([0, 3500]);
      setLoading(true);
      setPage('1');
      // setBbox([0;0,0,0]),
      setView(view);

      localStorage.removeItem('coming_soon');
      localStorage.removeItem('has_image');
      localStorage.removeItem('searchMinPrice');
      localStorage.removeItem('searchMaxPrice');
      localStorage.removeItem('searchTags');
      localStorage.removeItem('venueType');
      localStorage.removeItem('searchCheckbox');
      localStorage.removeItem('searchText');
      localStorage.removeItem('searchLocObj');

      setReloadItems(Math.random());
      setUpdateFilters(Math.random());
    }
  }, [clearFiltersTrigger]);

  useEffect(() => {
    if (page !== '1') {
      const query = { ...router.query };
      query.page = page;
      setPage(page);
      const newUrl = {
        pathname: router.pathname,
        query: query,
      };
      router.push(newUrl);
    } else {
      setReloadItems(Math.random());
    }
  }, [page]);

  const ctx = {
    reloadItems,
    setReloadItems,
    searchText,
    searchPrices,
    setSearchText,
    locationType,
    venueType,
    setLocationType,
    setVenueType,
    setLoading,
    center,
    setCenter,
    zoom,
    setZoom,
    searchTags,
    totalItems,
    setTotalItems,
    setSearchTags,
    setSearchPrices,
    comingSoon,
    onlyBook,
    setComingSoon,
    setOnlyBook,
    hasImage,
    setHasImage,
    setSearchCity,
    searchCity,
    page,
    setPage,
    bbox,
    setBbox,
    loading,
    view,
    setView,
    clearFilters,
    setClearFilters,
    clearFiltersTrigger,
    setClearFiltersTrigger,
    updateFilters,
    setUpdateFilters,
    selectedLocations,
    setSelectedLocations,
  };
  return ctx;
};

export default useMapState;
