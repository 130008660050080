import React, { useContext, useEffect, useState } from 'react';
import { IOptions } from 'interface/ISelect';
import Popover from '../atoms/popover/popover';
import { useIntl, FormattedMessage } from 'react-intl';
import { Button, ComingSoon, Input, RadioBox, SelectInput } from '../atoms';
import { useMapContext } from 'context/publish/map.provider';
import { Campaigns } from 'api/campaigns';
import { ChevronDown, ChevronLeft, ChevronRight, ChevronUp, Filter2, InfoCircle } from 'react-iconly';
import Checkbox, { ICheckboxProps } from '../atoms/Checkbox';
import { oneLine } from 'common-tags';
import MapLegend from '../atoms/MapLegend';
import styled from 'styled-components';
import ReactSlider from 'react-slider';
import { ISugestion } from 'interface/ISugestion';
import MapSearch from './MapSearch';
import ReactTooltip from 'react-tooltip';
import { useVenues } from '@utils/venues';
import Item from './DisplayList/Item';
import { useNetworks } from '@utils/networks';
import { JWT } from '@api/users';
import { CAMPAIGNS } from 'api';
import { PublishContext } from 'context/publish/publish.provider';

const StyledSlider = styled(ReactSlider)`
  width: 100%;
  height: 10px;
`;

const StyledThumb = styled.div`
  height: 25px;
  line-height: 25px;
  width: 25px;
  font-size: 10px;
  text-align: center;
  background-color: #f54747;
  color: #fff;
  border-radius: 50%;
  cursor: grab;
  z-index: 99999 !important;
  transform: translateX(-7px);
  transform: translateY(-7px);
`;

const Thumb = (props: any, state: any) => <StyledThumb {...props}>{state.valueNow}</StyledThumb>;

const StyledTrack = styled.div`
  top: 0;
  bottom: 0;
  background: ${(props: any) => (props.index === 2 ? '#dedede' : props.index === 1 ? '#f54747' : '#dedede')};
  border-radius: 999px;
`;

const Track = (props: any, state: any) => <StyledTrack {...props} index={state.index} />;

const StyledContainer = styled.div`
  overflow: auto;
  width: 100%;
  max-width: 100%;
  padding-right: 12px;
  height: 25px;
  padding-top: 7px;
`;

type IProps = {
  onChange: (q: string) => void;
  searchText: string;
  tags: IOptions[];
  onChangeCheckbox: (item: string, isChecked: string) => void;
  onChangeTags: (items: IOptions[]) => void;
};
export const FilterCampaign: React.FC<IProps> = ({
  onChange,
  onChangeCheckbox,
  searchText,
  tags,
  onChangeTags,
}: IProps) => {
  const { searchPrices, setSearchPrices, setReloadItems, setClearFiltersTrigger, clearFiltersTrigger } =
    useMapContext();
  const [isAdmin, setIsAdmin] = React.useState<boolean>(false);
  const { publishState, dispatch } = React.useContext(PublishContext);
  useEffect(() => {
    let user = JWT.getJwtUser();
    if (user.role === 'admin') setIsAdmin(true);
  }, []);
  const intl = useIntl();
  // const [tagsValues, setTagsValues] = React.useState<any>([]);
  const [selectedTags, setSelectedTags] = React.useState<IOptions[]>([]);

  const [localSeachPrices, setLocalSrachPrices] = React.useState<number[]>(searchPrices);

  const [scrollLeftVisible, setScrollLeftVisible] = React.useState(false);
  const [scrollRightVisible, setScrollRightVisible] = React.useState(true);
  const scrollContainerRef = React.useRef(null);
  const [themeMode, setThemeMode] = React.useState<string | null>(null);
  const [searchTerm, setSearchTerm] = React.useState('');

  const handleScroll = () => {
    const container = scrollContainerRef.current;

    if (container) {
      // @ts-ignore
      setScrollLeftVisible(container.scrollLeft > 0);
      // @ts-ignore
      setScrollRightVisible(container.scrollLeft < container.scrollWidth - container.clientWidth - 2);
    }
  };

  const handleScrollLeft = () => {
    const container = scrollContainerRef.current;
    if (container) {
      // @ts-ignore
      container.scrollTo({
        // @ts-ignore
        left: container.scrollLeft - 500,
        behavior: 'smooth',
      });
    }
  };

  const handleScrollRight = () => {
    const container = scrollContainerRef.current;
    if (container) {
      // @ts-ignore
      container.scrollTo({
        // @ts-ignore
        left: container.scrollLeft + 500,
        behavior: 'smooth',
      });
    }
  };

  useEffect(() => {
    const items = localStorage.getItem('mode');
    if (items) {
      setThemeMode(items);
    }
    const container = scrollContainerRef.current;
    if (container) {
      // @ts-ignore
      container.addEventListener('scroll', handleScroll);
      return () => {
        // @ts-ignore
        container.removeEventListener('scroll', handleScroll);
      };
    }
  }, []);

  useEffect(() => {
    let getSelectedTags = localStorage.getItem('searchTags')
      ? JSON.parse(localStorage.getItem('searchTags') || '')
      : [];
    setSelectedTags(getSelectedTags);
    // // setTagsValues(tagsValue);
    const minPrice = localStorage.getItem('searchMinPrice') ? Number(localStorage.getItem('searchMinPrice')) : 0;
    const maxPrice = localStorage.getItem('searchMaxPrice') ? Number(localStorage.getItem('searchMaxPrice')) : 3500;
    setSearchPrices([minPrice, maxPrice]);
    setLocalSrachPrices([minPrice, maxPrice]);
  }, []);

  const filterColors = (inputValue: string) => {
    return tags.filter(i => i.value.toLowerCase().includes(inputValue.toLowerCase()));
  };

  const { onlyBook, setOnlyBook } = useMapContext();
  const { comingSoon, setComingSoon } = useMapContext();
  const { hasImage, setHasImage } = useMapContext();
  const getAsyncOptions = (inputValue: any) =>
    new Promise(resolve => {
      setTimeout(() => {
        resolve(filterColors(inputValue));
      }, 1000);
    });

  const handleClickTag = (tag: IOptions) => {
    if (selectedTags.some(item => item.value === tag.value)) {
      const updatedTags = selectedTags.filter(item => item.value !== tag.value);
      setSelectedTags(updatedTags);
      onChangeTags(updatedTags);
      setReloadItems(Math.random()); 
    } else {
      setSelectedTags([...selectedTags, tag]);
      onChangeTags([...selectedTags, tag]);
      setReloadItems(Math.random()); 
    }
  };

  // const initialLoad = React.useRef(true);

  // React.useEffect(() => {
  //   console.log("selectedTags", selectedTags)
  //   onChangeTags(selectedTags);
  //   setReloadItems(Math.random()); 
  // }, [selectedTags]);

  const groupedTags = tags.reduce((acc: any, tagItem): any => {
    const firstLetter = tagItem.label.charAt(0).toUpperCase(); 
    if (!acc[firstLetter]) {
      acc[firstLetter] = [];
    }
    acc[firstLetter].push(tagItem);
    return acc;
  }, {});

  const handleSearch = (event: any) => {
    setSearchTerm(event.target.value);
  };

  return (
    <div className="relative w-full z-100 mob-content flex flex-col px-4 gap-6 overflow-y-auto no-scrollbar">
      <div className="">
        <div
          className="flex w-full flex-col items-end justify-center mb-4"
          onClick={() => {
            setClearFiltersTrigger(Math.random());
          }}
        >
          <h3 className="text-md font-semibold  dark:text-primary-500 cursor-pointer hover:underline">
            Clear all filters
          </h3>
        </div>
        <div className="flex w-full flex-col items-start justify-center mb-4">
          <h3 className="text-md font-semibold  dark:text-white">Map options</h3>
          <p className="text-sm font-normal text-dark-500 dark:text-dark-300 mt-2 text-left">
            Select your preferred display settings
          </p>
        </div>
        <div className="py-2 flex flex-row gap-6 flex-wrap">
          <Checkbox
            name="primary"
            onChange={(e: any) => {
              if (e.target.checked) {
                setComingSoon('1');
                localStorage.setItem('coming_soon', '1');
              } else {
                setComingSoon('0');
                localStorage.setItem('coming_soon', '0');
              }
              setReloadItems(Math.random());
            }}
            labelColor="dark"
            label={intl.formatMessage({
              defaultMessage: 'Type of screen',
              id: 'Label.ShowComingSoon',
            })}
            value={true}
            checked={localStorage.getItem('coming_soon') === '1'}
          />
          <Checkbox
            name="primary"
            onChange={(e: any) => {
              if (e.target.checked) {
                setHasImage('1');
                localStorage.setItem('has_image', '1');
              } else {
                setHasImage('0');
                localStorage.setItem('has_image', '0');
              }
              setReloadItems(Math.random());
            }}
            labelColor="dark"
            label={intl.formatMessage({
              defaultMessage: 'Type of screen',
              id: 'Label.ShowLocationsWithoutPictures',
            })}
            value={true}
            checked={localStorage.getItem('has_image') === '1'}
          />
          {publishState.locations && publishState.locations?.length > 0 && 
            <Checkbox
              name="primary"
              onChange={async (e: any) => {
                const saveDraft = await CAMPAIGNS.saveCampaignDraft({ ...publishState, maxStep: 3 }).catch(err => console.log(err));
                if(saveDraft.data.message === "Draft saved") {
                  if (e.target.checked) {
                    setOnlyBook('1');
                    localStorage.setItem('only_book', '1');
                  } else {
                    setOnlyBook('0');
                    localStorage.setItem('only_book', '0');
                  }
                  setReloadItems(Math.random());
                }
              }}
              labelColor="dark"
              label={intl.formatMessage({
                defaultMessage: 'Show only booked',
                id: 'Label.ShowOnlyBooked',
              })}
              value={true}
              checked={localStorage.getItem('only_book') === '1'}
            />
          }
        </div>
      </div>
      <div className="border-bordercolor range">
        <div className="flex w-full flex-col items-start justify-center mb-4">
          <h3 className="text-md font-semibold  dark:text-white">Price range</h3>
          <p className="text-sm font-normal text-dark-500 dark:text-dark-300 mt-2 text-left">
            Define your preferred price per hour range for billboards
          </p>
        </div>
        <StyledContainer>
          <StyledSlider
            max={3500}
            value={localSeachPrices}
            renderTrack={Track}
            renderThumb={Thumb}
            onChange={(prices: [number, number]) => {
              setLocalSrachPrices(prices);
            }}
            onAfterChange={(prices: [number, number]) => {
              setSearchPrices(prices);
              localStorage.setItem('searchMinPrice', String(prices[0]));
              localStorage.setItem('searchMaxPrice', String(prices[1]));
              setReloadItems(Math.random());
            }}
          />
        </StyledContainer>
        <div className="flex flex-row space-x-4 pt-6">
          <Input
            name="minPrice"
            type="number"
            className="border border-primary-500"
            min={0}
            max={searchPrices[1]}
            borderLight={false}
            onChange={(e: any) => {
              if (e.target.value) {
                if (e.target.value > searchPrices[1]) {
                  return;
                }
                setLocalSrachPrices([Number(e.target.value), searchPrices[1]]);
                setSearchPrices([Number(e.target.value), searchPrices[1]]);
                localStorage.setItem('searchMinPrice', e.target.value);
                setReloadItems(Math.random());
              } else {
                if (e.target.value === '') {
                  setLocalSrachPrices([Number(0), searchPrices[1]]);
                  setSearchPrices([Number(0), searchPrices[1]]);
                  localStorage.setItem('searchMinPrice', '0');
                  setReloadItems(Math.random());
                }
              }
            }}
            inputValue={String(localSeachPrices[0])}
            label={intl.formatMessage({
              defaultMessage: 'Min Price',
              id: 'Label.MinPrice',
            })}
          />
          <Input
            name="maxPrice"
            type="number"
            borderLight={false}
            max={3500}
            min={searchPrices[0]}
            onChange={(e: any) => {
              if (e.target.value) {
                setLocalSrachPrices([searchPrices[0], Number(e.target.value)]);
                setSearchPrices([searchPrices[0], Number(e.target.value)]);
                localStorage.setItem('searchMaxPrice', e.target.value);
                setReloadItems(Math.random());
              } else {
                if (e.target.value === '') {
                  setLocalSrachPrices([searchPrices[0], Number(0)]);
                  setSearchPrices([searchPrices[0], Number(0)]);
                  localStorage.setItem('searchMaxPrice', e.target.value);
                }
                setReloadItems(Math.random());
              }
            }}
            onBlur={(e: any) => {
              if (e.target.value < searchPrices[0]) {
                e.target.value = searchPrices[0];
                setLocalSrachPrices([searchPrices[0], searchPrices[0]]);
                setSearchPrices([searchPrices[0], searchPrices[0]]);
                localStorage.setItem('searchMaxPrice', String(searchPrices[0]));
                setReloadItems(Math.random());
              }
            }}
            inputValue={String(localSeachPrices[1])}
            label={intl.formatMessage({
              defaultMessage: 'Max Price',
              id: 'Label.MaxPrice',
            })}
          />
        </div>
      </div>

      {isAdmin && (
        <div className="pb-4 -mt-4">
          <div className="flex w-full flex-col items-start justify-center mb-4">
            <div className="flex flex-row items-center gap-2">
              <h3 className="text-md font-semibold  dark:text-white">Networks</h3>
              {selectedTags.length > 0 && <span className="text-primary-500">- {selectedTags.length} selected</span>}
            </div>
            <p className="text-sm font-normal text-dark-500 dark:text-dark-300 mt-2 text-left">
              Select the billboard network that align with your preferences
            </p>
          </div>
          <div className="flex flex-row w-full relative">
            <div
              className="flex flex-col items-start w-full overflow-x-auto gap-6 pt-2 pb-2 no-scrollbar"
              ref={scrollContainerRef}
            >
              {Object.entries(groupedTags).map(([letter, tagsStartingWithLetter]: any) => (
                <div className="flex flex-row" key={letter}>
                  <div className="text-lg font-bold dark:text-dark-400 pr-2">{letter}</div>{' '}
                  {/* Afișăm litera ca titlu de categorie */}
                  <div className="flex flex-row items-center flex-wrap gap-2">
                    {tagsStartingWithLetter.map((tagItem: any, index: any) => {
                      const isTagSelected = selectedTags.map(tag => tag.value).includes(tagItem.value);
                      return (
                        <div
                          key={`${index}_${tagItem.label}`}
                          onClick={() => handleClickTag(tagItem)}
                          className={`${
                            isTagSelected
                              ? 'border border-primary-500'
                              : 'border dark:border-dark-300 border-dark-200 border-opacity-30 hover:dark:border-dark-400 hover:border-opacity-50'
                          }
                                    whitespace-nowrap flex flex-row items-center text-md dark:text-dark-400 dark:hover:text-white rounded-3xl  px-2 cursor-pointer`}
                        >
                          {tagItem.label}
                        </div>
                      );
                    })}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

type IPropsMapFilter = {
  children: React.ReactNode;
  showList?: any;
  onChangeMapSearch?: (location: ISugestion) => void;
  showLocList: (view: string) => void;
  showOnboarding?: () => void;
};

const PublishMapFilter: React.FC<IPropsMapFilter> = ({
  children,
  showList,
  onChangeMapSearch,
  showOnboarding,
  showLocList,
}: IPropsMapFilter) => {
  const {
    onlyBook,
    comingSoon,
    hasImage,
    searchText,
    locationType,
    venueType,
    setSearchText,
    setLocationType,
    setVenueType,
    searchTags,
    setSearchTags,
    setReloadItems,
    setSearchPrices,
    searchPrices,
    setSearchCity,
    view,
    clearFilters,
  } = useMapContext();
  const [tags, setTags] = React.useState<IOptions[]>([]);
  const [totalFilters, setTotalFilters] = React.useState<number>(0);
  const [showSearch, setShowSearch] = React.useState<boolean>(false);
  const [showFilters, setShowFilters] = React.useState<boolean>(false);
  const [optionsRadio, setOptionsRadio] = React.useState<any>([]);
  const [venues, setVenues] = React.useState<any[]>([]);
  const [scrollLeftVisible, setScrollLeftVisible] = React.useState(false);
  const [scrollRightVisible, setScrollRightVisible] = React.useState(true);
  const [testMode, setTestMode] = React.useState(true);
  const scrollContainerRef = React.useRef(null);
  const [themeMode, setThemeMode] = React.useState<string | null>(null);

  const venueCategoryInputRef = React.useRef(null);
  const moreFiltersComponentRef = React.useRef(null);

  const handleClickOutside = (event: MouseEvent) => {
    // @ts-ignore
    if (moreFiltersComponentRef.current && !moreFiltersComponentRef.current.contains(event.target)) {
      setShowFilters(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const handleVerticalScroll: React.WheelEventHandler<HTMLDivElement> = event => {
    if (scrollContainerRef.current) {
      // @ts-ignore
      scrollContainerRef.current.scrollLeft += event.deltaY;
    }
  };

  const handleScroll = () => {
    const container = scrollContainerRef.current;

    if (container) {
      // @ts-ignore
      setScrollLeftVisible(container.scrollLeft > 0);
      // @ts-ignore
      setScrollRightVisible(container.scrollLeft < container.scrollWidth - container.clientWidth - 2);
    }
  };

  const handleScrollLeft = () => {
    const container = scrollContainerRef.current;
    if (container) {
      // @ts-ignore
      container.scrollTo({
        // @ts-ignore
        left: container.scrollLeft - 500,
        behavior: 'smooth',
      });
    }
  };

  const handleScrollRight = () => {
    const container = scrollContainerRef.current;
    if (container) {
      // @ts-ignore
      container.scrollTo({
        // @ts-ignore
        left: container.scrollLeft + 500,
        behavior: 'smooth',
      });
    }
  };

  const onChange = (q: string) => {
    setSearchText(q);
  };

  useEffect(() => {
    const container = scrollContainerRef.current;
    if (container) {
      // @ts-ignore
      container.addEventListener('scroll', handleScroll);
      return () => {
        // @ts-ignore
        container.removeEventListener('scroll', handleScroll);
      };
    }
  }, []);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      handleScroll();
    }, 100);

    return () => clearTimeout(timeoutId);
  }, [venues]);

  useEffect(() => {
    getVenues();

    let types = locationType ? locationType : [];

    setOptionsRadio([
      { value: 'indoor', label: 'Indoor', checked: types.indexOf('indoor') > -1, color: '#F1CB69' },
      { value: 'outdoor', label: 'Outdoor', checked: types.indexOf('outdoor') > -1, color: '#CC9CF2' },
      { value: 'mobile', label: 'Mobile', checked: types.indexOf('mobile') > -1, color: '#B6EE9B' },
    ]);

    setSearchText(localStorage.getItem('searchText') || '');
    setSearchPrices([
      Number(localStorage.getItem('searchMinPrice')) || 0,
      Number(localStorage.getItem('searchMaxPrice')) || 3500,
    ]);

    if (localStorage.getItem('searchCheckbox'))
      setLocationType(JSON.parse(localStorage.getItem('searchCheckbox') || '') || []);

    if (localStorage.getItem('searchTags')) setSearchTags(JSON.parse(localStorage.getItem('searchTags') || '') || []);
  }, []);

  const onChangeCheckbox = (item: string, isChecked: string) => {
    let tempData = [...locationType];
    if (isChecked) tempData.push(item);
    else {
      tempData = tempData.filter((el: string) => el !== item);
    }
    tempData = tempData.filter((el: string) => el !== '');
    localStorage.setItem('searchCheckbox', JSON.stringify(tempData));
    setLocationType(tempData);
  };

  const getVenues = async () => {
    const getVenueTypes = await useVenues().venuesApi();
    // @ts-ignore
    // getVenueTypes[0].expand = true;
    if (localStorage.getItem('venueType')) {
      const defaultSelectedVenues = JSON.parse(localStorage.getItem('venueType') || '') || [];
      setVenueType(defaultSelectedVenues);

      const venuesCheckedUpdate = getVenueTypes;
      venuesCheckedUpdate.forEach(venue => {
        if (venue && venue.children) {
          const anyChildChecked = venue.children.some((child: any) => defaultSelectedVenues.includes(String(child.id)));
          // @ts-ignore
          venue.checked = anyChildChecked;
          venue.children.forEach((child: any) => {
            child.checked = defaultSelectedVenues.includes(String(child.id));
          });
        }
      });
      setVenues([...venuesCheckedUpdate]);
    } else {
      setVenues(getVenueTypes);
    }
  };

  const onVenueCategoryCheck = (index: any, el: any) => {
    if (venueCategoryInputRef.current) {
      // @ts-ignore
      venueCategoryInputRef.current.checked = !venues[index].checked;
      const updatedVenues = [...venues];
      // @ts-ignore
      updatedVenues[index].checked = venueCategoryInputRef.current.checked;
      updatedVenues[index].children.forEach((item: any) => {
        // @ts-ignore
        if (venueCategoryInputRef.current.checked && intersection(item.locationType)) {
          // @ts-ignore
          item.checked = venueCategoryInputRef.current.checked;
        } else {
          item.checked = false;
        }
      });
      updateVenues(updatedVenues[index].children);

      // setVenues([...updatedVenues]);
    }
  };

  const onVenueItemCheck = (parentIndex: any, childIndex: any) => {
    const updatedVenues = [...venues];
    updatedVenues[parentIndex].children[childIndex].checked = !updatedVenues[parentIndex].children[childIndex].checked;

    const allChildrenChecked = updatedVenues[parentIndex].children.every((child: any) => child.checked);
    updatedVenues[parentIndex].checked = allChildrenChecked;

    const allChildrenUnchecked = updatedVenues[parentIndex].children.every((child: any) => !child.checked);
    if (allChildrenUnchecked) {
      updatedVenues[parentIndex].checked = false;
    }

    const anyChildChecked = updatedVenues[parentIndex].children.some((child: any) => child.checked);

    updatedVenues[parentIndex].checked = anyChildChecked;

    setVenues([...updatedVenues]);
    updateVenues(updatedVenues[parentIndex].children[childIndex]);
  };

  const handleClickVenue = (venue: any) => {
    const updatedVenues = [...venues];
    updatedVenues.forEach(item => {
      if (item.id === venue.id) {
        item.expand = !item.expand;
      } else {
        item.expand = false;
      }
    });
    setVenues([...updatedVenues]);
  };

  const updateVenues = (venuesToUpdate: any | any[]) => {
    const updatedVenues = Array.isArray(venuesToUpdate)
      ? venuesToUpdate.map(venue => ({ id: String(venue.id), checked: venue.checked }))
      : [{ id: String(venuesToUpdate.id), checked: venuesToUpdate.checked }];

    const existingVenueIds = venueType.map(item => item);

    updatedVenues.forEach(venue => {
      const index = existingVenueIds.indexOf(venue.id);

      if (venue.checked && index === -1) {
        // @ts-ignore
        setVenueType(prev => [...prev, venue.id]);
      } else if (!venue.checked && index !== -1) {
        // @ts-ignore
        setVenueType(prev => prev.filter(item => item !== venue.id));
      }
    });
    setReloadItems(Math.random());
  };

  const onChangeTags = (item: IOptions[]) => {
    if (item) localStorage.setItem('searchTags', JSON.stringify(item));
    else localStorage.setItem('searchTags', JSON.stringify([]));

    setSearchTags(item);
  };

  const getTags = async () => {
    // const response = await Campaigns.getTags();
    const response = await CAMPAIGNS.getNetworks();
    const uniqueNetworksMap: Map<string, any> = new Map();

    response.networks.forEach((network: any) => {
      uniqueNetworksMap.set(network.uuid, network);
    });

    const uniqueNetworksArray: any[] = Array.from(uniqueNetworksMap.values());

    if (uniqueNetworksArray) {
      let t = uniqueNetworksArray.map((el: any) => {
        return {
          value: `${el.sId}`,
          label: `${el.name}`,
        };
      });
      t.sort((a, b) => a.label.localeCompare(b.label));
      setTags(t);
    }
  };

  useEffect(() => {
    const items = localStorage.getItem('mode');
    if (items) {
      setThemeMode(items);
    }
    getTags();
  }, []);

  useEffect(() => {
    let total = 0;
    if (searchPrices[0] > 0 || searchPrices[1] < 3500) total++;
    if (searchTags && searchTags.length !== 0) {
      total += searchTags.length;
    }
    if (onlyBook === '1') {
      total++;
    }
    if (comingSoon === '1') {
      total++;
    }
    if (hasImage === '0') {
      total++;
    }
    setTotalFilters(total);
    return () => {};
  }, [searchTags?.length, searchPrices, comingSoon, hasImage, onlyBook]);

  const pillClasses = oneLine`
  capitalize
  text-white text-xs
  py-1 px-2
  bg-primary-500 
  rounded-xl
`;
  const [showScreenType, setScreenType] = React.useState<boolean>(false);
  const [viewModeOnboarding, setViewModeOnboarding] = React.useState<boolean>(
    localStorage.getItem('viewModeOnboarding') === 'hidden' ? false : true
  );
  const [viewOptions, setViewOptions] = useState([
    { value: 'List', label: 'List', checked: view === 'List' },
    { value: 'Half', label: 'Split', checked: view === 'Half' },
    { value: 'Map', label: 'Map', checked: view === 'Map' },
  ]);
  const viewOptionsMobile = [
    { value: 'List', label: 'List', checked: view === 'List' },
    { value: 'Map', label: 'Map', checked: view === 'Map' || view === 'Half' },
  ];
  useEffect(() => {
    setViewOptions(
      viewOptions.map(el => {
        return {
          ...el,
          checked: el.value === view,
        };
      })
    );
  }, [view]);

  const intersection = (multimeA: any): boolean => {
    const copyLocationType = locationType.length > 0 ? locationType : ['indoor', 'outdoor', 'mobile'];

    const copieMultimeA = new Set(multimeA);
    const copieMultimeB = new Set(copyLocationType);

    return [...copieMultimeA].filter((element: any) => copieMultimeB.has(element)).length > 0;
  };

  return (
    <div className="flex flex-col relative items-center w-full px-4 py-2">
      <div className="flex flex-row w-full gap-2 items-center mb-2">
        <div className="w-4/12 md:w-3/12">
          <MapSearch
            fromCampaignPage={false}
            popoverMobile={true}
            defaultValueLocName={searchText}
            showList={showList?.current ?? false}
            onChangeLocName={(name: string) => {
              onChange(name);
            }}
            onChange={(location: ISugestion) => {
              setSearchCity(location?.city);
              // setReloadItems(Math.random());
              onChangeMapSearch?.(location);
            }}
            countryArea=""
          />
        </div>
        <Popover
          className="flex-1 w-full leftbottom block md:hidden"
          handler={
            <div
              onClick={() => {
                // setScreenType(!showScreenType);
              }}
              className="justify-center dark:text-dark-400 flex p-2 transition-all h-full duration-300 ease-in-out border lg:hidden md:hidden border-color dark:border-dark-400 rounded-2xl align-items min-w-[115px] space-x-2"
            >
              <Filter2 size={18} set="light" />
              <span className="whitespace-nowrap dark:text-dark-400 font-semibold">Screen Type</span>
            </div>
          }
          content={
            <div className={'gap-2 space-y-2 md:flex !left-0'}>
              {optionsRadio.map((el: ICheckboxProps, key: number) => {
                return (
                  <div
                    key={`${key}_${el.value}_${el.checked}`}
                    className={`bg-dark-300/20 px-4 py-2 rounded-xl flex items-center`}
                    data-cy={'filter' + el.label}
                  >
                    <div className="flex flex-row items-center h-[20px]">
                      <Checkbox
                        key={`${key}_${el.value}_${el.checked}`}
                        name="primary"
                        onChange={(e: any) => {
                          onChangeCheckbox(e.target.value, e.target.checked);
                          setReloadItems(Math.random());
                        }}
                        labelColor="dark"
                        label={el.label}
                        value={el.value}
                        checked={el.checked}
                        bulletColor={el.color}
                      />
                    </div>
                  </div>
                );
              })}
            </div>
          }
        />

        <div  className="search-popover-web w-3/12">
          <Input
            name="search"
            type="text"
            borderLight={true}
            startIcon="Search"
            onChange={(e: any) => {
              onChange(e.target.value);
              setReloadItems(Math.random());
              localStorage.setItem('searchText', e.target.value);
            }}
            defaultValue={searchText}
            placeholder={'Search by billboard name'}
            label=""
            fullWidth={true}
          />
        </div>
        <div className={`gap-2 ${!showScreenType ? 'hidden md:flex' : 'md:flex'}`}>
          {optionsRadio.map((el: ICheckboxProps, key: number) => {
            return (
              <div
                key={`${key}_${el.value}_${el.checked}`}
                className={`bg-dark-300/20 px-4 py-2 rounded-xl flex items-center`}
                data-cy={'filter' + el.label}
              >
                <div className="flex flex-row items-center h-[20px]">
                  <Checkbox
                    key={`${key}_${el.value}_${el.checked}`}
                    name="primary"
                    onChange={(e: any) => {
                      onChangeCheckbox(e.target.value, e.target.checked);
                      setReloadItems(Math.random());
                    }}
                    labelColor="dark"
                    label={el.label}
                    value={el.value}
                    checked={el.checked}
                    bulletColor={el.color}
                  />
                </div>
              </div>
            );
          })}
        </div>
        <div
          className={`flex-1 relative hidden md:flex  gap-6 rounded-b-xl text-dark-400  items-center jusify-center dark:bg-dark-200`}
        >
          {viewModeOnboarding && (
            <div className="absolute top-full right-full z-110 fadeInRight">
              <div className="flex flex-row">
                <div className="dark:bg-dark-default bg-white whitespace-nowrap w-full flex flex-col items-center relative p-6 font-semibold rounded-2xl h-full mt-20 -mb-16 -mr-6 ml-6 text-accent-500 box-shadow-container-light dark:dark-shadow">
                  <span>Check out our latest feature!</span>
                  <span className="dark:text-dark-400 text-dark-200 mt-2 font-normal">
                    Mix it up for easier billboard spotting
                  </span>
                  <button
                    onClick={e => {
                      setViewModeOnboarding(false);
                      localStorage.setItem('viewModeOnboarding', 'hidden');
                      e.stopPropagation();
                    }}
                    data-cy="popClose"
                    className="absolute px-2 py-2 transition duration-300 bg-white rounded-full -left-9 -top-9 md:px-2 md:py-2 border-2 border-primary-default dark:bg-dark-default z-99999 md:-left-4 md:-top-4 hover:rotate-180"
                  >
                    <svg
                      className="dark:hidden"
                      width="15"
                      height="15"
                      viewBox="0 0 30 30"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M8.98959 9.01041L21.0104 21.0312M21.0104 9.01041L8.98959 21.0312"
                        stroke="#f53d3f"
                        strokeWidth="2.5"
                        strokeLinecap="round"
                      />
                    </svg>
                    <svg
                      className="hidden dark:block"
                      width="15"
                      height="15"
                      viewBox="0 0 30 30"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M8.98959 9.01041L21.0104 21.0312M21.0104 9.01041L8.98959 21.0312"
                        stroke="#f53d3f"
                        strokeWidth="2.5"
                        strokeLinecap="round"
                      />
                    </svg>
                  </button>
                </div>
                <img className="ml-4 w-28 -rotate-45" src="/images/arrow-referral.svg" />
              </div>
            </div>
          )}
          <RadioBox
            boxSize="big"
            key={`1_${viewOptions[0].checked}_${viewOptions[1].checked}`}
            type="radio"
            name={`map-view`}
            flexClass="flex-1 is-center-box"
            onChange={(e: any) => {
              const l = e.target.value;
              showLocList(l);
            }}
            options={viewOptions}
          />
        </div>
        <div
          className={`flex-1 flex md:hidden gap-6 rounded-b-xl text-dark-400  items-center jusify-center dark:bg-dark-200`}
        >
          <RadioBox
            boxSize="big"
            key={`2`}
            type="radio"
            name={`map-view-mobile`}
            flexClass="flex-1 is-center-box"
            onChange={(e: any) => {
              const l = e.target.value;
              showLocList(l);
            }}
            options={viewOptionsMobile}
          />
        </div>
        {/* <div className="w-1/12 md:w-1/12">
          <div
            onClick={() => {
              showOnboarding?.();
            }}
            data-tip
            data-for="onboarding"
            className={`hidden md:flex bg-white dark:bg-dark-200 ml-4 p-2 text-white rounded-20 cursor-pointer pointer-events-auto whitespace-nowrap 
              tracking-tighter md:text-lightGrey dark:md:text-white dark:text-primarydark-default text-primarydark-default text-md 
              dark:hover:text-primary-500 border border-dark-400 dark:hover:border-primary-500 items-center justify-center gap-2
              `}
          >
            <InfoCircle set="light" size="small" />
            <div className="hidden md:block">Helper</div>
          </div>
        </div> */}
      </div>

      <div className="flex flex-row w-full relative">
        {/* <div className='p-2 dark:text-white underline cursor-pointer' onClick={() =>setTestMode(!testMode)}>{testMode ? 'RV' : 'TV'}</div> */}
        <div
          onClick={handleScrollLeft}
          className={`${
            scrollLeftVisible ? '' : 'hidden'
          }  relative bg-opacity-20 md:px-2 flex-1 flex items-center cursor-pointer ${
            themeMode === 'darkMode' ? 'scroll-btn-left' : 'scroll-btn-left-light'
          }`}
        >
          <div className="border dark:border-dark-300 border-dark-200 dark:border-opacity-30 border-opacity-50 rounded-full md:p-2">
            <ChevronLeft set="light" primaryColor={themeMode === 'darkMode' ? '#E4DEDE' : '#1A1A1A'} size="small" />
          </div>
        </div>
        <div
          className="flex flex-row items-center w-full overflow-x-auto gap-6 no-scrollbar"
          ref={scrollContainerRef}
          onWheel={handleVerticalScroll}
        >
          {venues.map((venue, index) => {
            return (
              <div
                key={`${index + '_'}`}
                className={`bg-dark-300 bg-opacity-10 rounded-xl
                    whitespace-nowrap flex flex-row items-center justify-start 
                    cursor-pointer dark:text-dark-400 text-dark-200 
                    ${venue.expand ? 'expanding max-w-min' : ''} 
                    ${
                      venue.checked && !venue.expand
                        ? 'border border-primary-500'
                        : 'border border-dark-400 border-opacity-30'
                    }
                  `}
              >
                <div
                  className={`bg-dark-300 bg-opacity-20 p-2 rounded-l-xl flex flex-row items-center gap-2   ${
                    intersection(venue.locationType)
                      ? 'dark:hover:text-white hover:underline'
                      : 'line-through dark:text-dark-300 text-dark-200 text-opacity-40 dark:text-opacity-40'
                  }`}
                  onClick={e => {
                    !venue.expand
                      ? handleClickVenue(venue)
                      : !(!venue.checked && !intersection(venue.locationType)) && onVenueCategoryCheck(index, e);
                  }}
                >
                  {venue.expand && (
                    <input
                      key={`${index + '_' + venue.checked}`}
                      ref={venueCategoryInputRef}
                      name={'venueCategory' + index}
                      className={`
                            ${!(!venue.checked && !intersection(venue.locationType)) ? '' : 'hidden'}
                            ease-in-out transition duration-300
                            animated rounded
                            cursor-pointer
                            border
                            border-grey-200
                            w-5
                            h-5
                            checked:bg-primary-500 hover:border-primary-500 text-primary-500 focus:ring-0 focus:ring-offset-0
                          `}
                      type="checkbox"
                      defaultChecked={venue.checked}
                      disabled={!venue.checked && !intersection(venue.locationType)}
                    />
                  )}
                  <span className="">{venue.title}</span>
                  {/* {testMode && venue.locationType.map((lt: string) => {
                      return <>{"|"} <span className='italic'> {lt} </span></>;
                    })} */}
                  {venue.checked && (
                    <span className="text-primary-500">
                      ({venue.children.filter((child: any) => child.checked === true).length}/{venue.children.length})
                    </span>
                  )}
                </div>
                {venue.expand && (
                  <div className={`flex flex-row gap-2 py-2 items-start justify-start h-full overflow-x-hidden pl-2`}>
                    {venue.children.map((child: any, indexChildren: number) => {
                      return (
                        <label
                          className={`flex flex-row items-center  border-r dark:border-opacity-30 pr-2 gap-2 
                            ${
                              intersection(child.locationType)
                                ? 'dark:hover:text-white hover:underline cursor-pointer'
                                : 'line-through dark:text-dark-300 text-dark-200 text-opacity-40 dark:text-opacity-40'
                            }
                            `}
                          key={`${index + '_' + indexChildren + '_' + child.checked}`}
                        >
                          <input
                            className={`
                                  ${!(!child.checked && !intersection(child.locationType)) ? '' : 'hidden'}
                                  ease-in-out transition duration-300
                                  animated rounded
                                  cursor-pointer
                                  border
                                  border-grey-200
                                  w-3
                                  h-3
                                  checked:bg-primary-500 hover:border-primary-500 text-primary-500 focus:ring-0 focus:ring-offset-0
                                `}
                            disabled={!child.checked && !intersection(child.locationType)}
                            type="checkbox"
                            defaultChecked={child.checked}
                            onChange={() => onVenueItemCheck(index, indexChildren)}
                          />
                          {child.title}
                          {/* {" "} */}
                          {/* {testMode && child.locationType.map((lt: string) => {
                              return <>{"|"} <span className='italic'> {lt} </span></>;
                            })} */}

                          <div className={`w-5 h-5 ${intersection(child.locationType) ? '' : 'opacity-50'}`}>
                            <img
                              src={`/images/venue/${
                                themeMode !== 'darkMode' ? child.title + '-dark.svg' : child.title + '.svg'
                              }`}
                            ></img>
                          </div>
                        </label>
                      );
                    })}
                  </div>
                )}
                <div
                  onClick={e => {
                    handleClickVenue(venue);
                  }}
                  className="py-2"
                >
                  {(venue.expand && (
                    <ChevronLeft
                      set="light"
                      primaryColor={themeMode === 'darkMode' ? '#f53d3f' : '#f53d3f'}
                      size="small"
                    />
                  )) || (
                    <ChevronRight
                      set="light"
                      primaryColor={themeMode === 'darkMode' ? '#E4DEDE' : '#1A1A1A'}
                      size="small"
                    />
                  )}
                </div>
              </div>
            );
          })}
          {venues.length === 0 && <div className="font-semibold p-2 border border-lightGrey opacity-0">a</div>}
        </div>
        <div
          onClick={handleScrollRight}
          className={`${
            scrollRightVisible ? '' : 'hidden'
          }  relative bg-opacity-20 md:px-2 flex-1 flex items-center cursor-pointer ${
            themeMode === 'darkMode' ? 'scroll-btn-right' : 'scroll-btn-right-light'
          }`}
        >
          <div className="border dark:border-dark-300 border-dark-200 dark:border-opacity-30 border-opacity-50 rounded-full md:p-2">
            <ChevronRight set="light" primaryColor={themeMode === 'darkMode' ? '#E4DEDE' : '#1A1A1A'} size="small" />
          </div>
        </div>
        {venueType.length > 0 && (
          <Popover
            className=""
            handler={
              <div className="whitespace-nowrap text-primary-500 border border-primary-500 flex items-center rounded-xl py-2 px-2 underline cursor-pointer ml-4 font-semibold">
                {venueType.length} selected
              </div>
            }
            content={
              <>
                <div className="p-4">
                  <div className="flex flex-col min-w-[300px] max-h-[500px] whitespace-nowrap items-start space-y-4 justify-start border-opacity-30 pr-2 gap-2 dark:text-dark-400">
                    {venues.map((venue, index) => {
                      return (
                        <>
                          {venue.checked && (
                            <div className="flex flex-col justify-start">
                              <h2 className="font-semibold">{venue.title}</h2>
                              <div className="mt-4 ml-4 flex flex-col space-y-2">
                                {venue.children.map((childr: any, indexChildren: number) => {
                                  return (
                                    <>
                                      {childr.checked && (
                                        <div
                                          className="flex flex-row items-center cursor-pointer dark:hover:text-white gap-2 hover:underline"
                                          key={`${index + '_' + indexChildren + '_' + childr.checked}`}
                                          onClick={e => {
                                            onVenueItemCheck(index, indexChildren);
                                            e.stopPropagation();
                                          }}
                                        >
                                          <span className="dark:text-dark-300 text-dark-100 mb-1">x</span>
                                          {childr.title}
                                          <div className="w-5 h-5">
                                            <img
                                              src={`/images/venue/${
                                                themeMode !== 'darkMode'
                                                  ? childr.title + '-dark.svg'
                                                  : childr.title + '.svg'
                                              }`}
                                            ></img>
                                          </div>
                                        </div>
                                      )}
                                    </>
                                  );
                                })}
                              </div>
                            </div>
                          )}
                        </>
                      );
                    })}
                  </div>
                </div>
              </>
            }
          />
        )}
      </div>

      <div
        id="moreFilters"
        ref={moreFiltersComponentRef}
        className="flex-1 more-filters-width max-h-[70vh]  mb-4 gap-6 p-2 dark:filter-shadow-dark filter-shadow-light rounded-b-20 dark:text-dark-400 absolute top-full left-0 flex flex-col items-start jusify-center dark:bg-dark-200 bg-white"
      >
        <div
          onClick={() => {
            setShowFilters(!showFilters);
          }}
          className={`p-2 flex flex-row justify-between w-full cursor-pointer`}
        >
          <div>
            <span>{showFilters ? 'Hide filters' : 'Show more filters'} </span>
            {totalFilters > 0 && (
              <span className="text-primary-500">
                - {totalFilters} {totalFilters === 1 ? 'filter' : 'filters'} applied
              </span>
            )}
          </div>
          <div className={`${showFilters ? '' : 'rotate-180'}`}>
            <ChevronUp set="light" primaryColor={themeMode === 'darkMode' ? '#E4DEDE' : '#1A1A1A'} size="small" />
          </div>
        </div>
        {showFilters && (
          <FilterCampaign
            tags={tags}
            onChange={onChange}
            searchText={searchText}
            onChangeCheckbox={onChangeCheckbox}
            onChangeTags={onChangeTags}
          />
        )}
      </div>
      <div id="basket" className="flex-1 hidden md:flex p-4 mb-4 gap-6 rounded-b-20 dark:text-dark-400 absolute top-full right-0  items-center jusify-center dark:bg-dark-200 bg-white">
        {children}
      </div>
    </div>
  );
};
export default PublishMapFilter;
